@import "../../resources/scss/variable";
@import "../../resources/scss/mixin";

.price {
  color: #fff;
  font-weight: bold;
  position: relative;

  .symbol {
    font-size: 12px;
    color: #ffffff40;
    position: absolute;
    top: 0;
    left: calc(100% + 4px);
    text-transform: uppercase;
  }
}

.cart {
  color: white;
  background-color: #2c2c2c;
  height: auto;
  min-height: 100vh;
  position: relative;

  .cart-top {
    .heading {
      color: #ffffff90;
      font-size: 14px;
      margin-top: 10px;
      text-transform: none;
      margin-bottom: 0;
    }

    .title {
      color: #18ce36;
      font-size: 24px;
      .price {
        color: #18ce36;
        font-size: 24px;
      }
    }
    
    .delete-all-button {
      height: 40px;
      margin:5px !important;
      flex: 1;
      background-color: transparent;
      border: 2px solid #cc0000;
      color: #cc0000;
      position: absolute;
      right: 0;
      top: 10px;

      &:hover {
        background-color: #cc0000;
        color:white;
        cursor: pointer;
      }
    }
  }

  .right-button-wrap {
    flex: 0 1 100%;
    @include responsive(xs) {
      flex: 0 1 100px;
    }
  }

  .cart-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .cart-inner {
      flex: 6;
      margin-top: 15px;
      overflow-x: scroll;

      @include responsive(md) {
        margin-top: 0px;
      }
    }

    .cart-detail {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 1px solid #707070;
      padding-bottom: 1rem;
      @media screen and(max-width: 640px) {
        width: 100%;
        overflow-x: auto;
        // .cart-table {
        //   width: 640px;
        // }
      }
    }
  }

  .cart-header {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    font-weight: bold;
    color: white;
    font-size: 12px;
    letter-spacing: 0;
    padding-bottom: 5px;

    @include responsive(md) {
      font-size: 16px;
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }
  }

  .cart-row {
    width: 100%;
    border-radius: 6px;
    background-color: #262626e6;
    margin-top: 5px;
    padding: 5px 0 3px 0;
    display: flex;

    .ant-col {
      display: flex;
      justify-content: center;
      flex-direction: column;
      &:first-child,
      &:last-child {
        padding-left: 4px;
        padding-right: 4px;
      }
      overflow: hidden;
    }

    .product-ref {
      font-size: 9px;
      padding-right: 2px;
      color: lightgray;

      @include responsive(md) {
        font-size: 12px;
      }
    }

    .product-name {
      font-weight: bold;
      color: white;
      font-size: 11px;
      margin-bottom: 0;
      line-height: 1;

      @include responsive(md) {
        font-size: 14px;
      }
    }

    .product-sku {
      text-align: left;
      font-size: 10px;
      color: #ffffff66;
    }

    .product-price {
      text-align: left;
      font-size: 12px;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 4px;

      @include responsive(md) {
        font-size: 16px;
        font-weight: bold;
        justify-content: flex-start;
      }
      .symbol {
        font-size: 10px;
      }

      .price {
        font-weight: 400 !important;

        @include responsive(md) {
          font-weight: bold;
        }
      }
    }

    .decrease,
    .increase {
      font-size: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    .product-total {
      text-align: right;
      padding-right: 25px;
      font-size: 16px;

      .symbol {
        font-size: 10px;
      }
    }

    .actions {
      justify-content: center;

      .delete-button {
        color: #cc0000;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .cart-actions {
    .ant-col {
      padding-right: 1rem;
      margin-bottom: 1rem;

      &:last-child {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include responsive(md) {
          align-items: flex-end;
        }
      }
    }

    .notes-and-conditions {
      a {
        font-size: 12px;
        font-style: italic;
        color: #ffffff60;
        line-height: 1;
      }
    }

    .label {
      span {
        font-size: 12px;
        color: #fff;
        background-color: $primary-color;
        position: relative;
        display: inline-block;
        padding: 1px 10px 0.5px;
        font-style: italic;
        line-height: 21px;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 0 solid transparent;
          border-right: 22px solid transparent;
          border-bottom: 19px solid #24bb00;
          transform: rotate(90deg);
          right: -20px;
          top: 1px;
        }
      }
    }

    .total-price-section {
      padding-right: 1.5rem;

      .price {
        font-size: 24px;
        display: inline-block;
        margin-top: 5px;
      }
    }

    .cart-buttons {
      margin-top: 10px;
      display: flex;
      flex-direction: row-reverse !important;
      flex-wrap: wrap;
      align-items: center;

      .ant-btn {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        height: 40px;
        border: none;
        font-weight: bold;
        display: block;
        margin:5px;
        max-width: 200px;
      }

      .checkout-button {
        min-height: 40px;
        height: 40px;
        flex: 1;
        background-color: $primary-color;
        color: #fff;
        margin:5px !important;

        &:hover {
          background-color: darken($primary-color, 20%);
        }
      }

      .continue-button {
        height: 40px;
        margin:5px !important;
        flex: 1;
        background-color: transparent;
        border: 2px solid #ffffff60;
        color: #ffffff60;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.product-title {
  font-weight: bold;
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include responsive(md) {
    font-size: 1em;
  }
}

.product-title_vn {
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include responsive(md) {
    font-size: 0.88em;
  }
}
