
.part_header {
    border-bottom: 2px solid rgba(255,255,255,0.2);
    height: 30px;
    font-weight: bold;
    color:white;
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 4px;
}
.group_name {
    text-align: left;
    letter-spacing: 0.58px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    line-height: 37px;
    height: 54px;
}
.title {
    font-weight: bold;
    color:white;
    font-size: 0.9em;
    letter-spacing: 0;
}
.title_vn {
    color:white;
    font-size: 0.88em;
    letter-spacing: 0;
}
.part_name {
    font-weight: bold;
    color:white;
    font-size: 1.1vmin;
    letter-spacing: 0;

}
.new_code{
    text-align: left;
    font-size: 0.72em;
    letter-spacing: 0;
    color: #FFCE00C4;
}
.old_code{
    text-align: left;
    font-size: 0.72em;
    letter-spacing: 0;
    color: #FFFFFF66;
    text-decoration: line-through;
}
.code{
    text-align: left;
    font-size: 0.72em;
    letter-spacing: 0;
    color: #FFFFFF66;
}
.inventory {
    text-align: right;
    font-size: 19px;
    letter-spacing: 0;
    padding-right: 5px;
    min-width: 30px;
}
.part_location {
    position: absolute;
    right: 100px;
    top: 0px;
    height: 100%;
    align-items: center;
    display: flex;
}
.name_box {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding:5px;
    align-items: center;
}
.shopping_cart {
    background: #24BB00 0% 0% no-repeat padding-box;
    border-radius: 0px 6px 6px 0px;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display:flex;
}
.shopping_cart_disable {
    background: rgb(61, 61, 61) 0% 0% no-repeat padding-box;
    border-radius: 0px 6px 6px 0px;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display:flex;
}
.part_item_box {
    width: 100%;
    height: 40px;
    border-radius: 6px; 
    background-color: #262626E6;
    margin-top:5px;
    user-select: none;
    margin-bottom: 0px;
}
.part_item_col {
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display:flex;
    padding:5px;
}