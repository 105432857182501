@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=vietnamese);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=vietnamese);
img {
  max-width: 100%; }

.fs-12, .fs-12 * {
  font-size: 12px; }

.primary-color {
  color: #24BB00; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.m-0 {
  margin: 0 !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.m-1 {
  margin: 0.25rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.m-3 {
  margin: 1rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.m-5 {
  margin: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.p-1 {
  padding: 0.25rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.p-3 {
  padding: 1rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.p-5 {
  padding: 3rem !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .p-sm-5 {
    padding: 3rem !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .p-md-5 {
    padding: 3rem !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .p-lg-5 {
    padding: 3rem !important; } }

html {
  scroll-behavior: smooth; }
  html body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  html code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.container {
  max-width: 1380px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px; }
  @media only screen and (min-width: 768px) {
    .container {
      padding-left: 1rem;
      padding-right: 1rem; } }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ant-layout-sider-zero-width-trigger {
  top: 50px !important; }

.login-form-container {
  width: 100%;
  min-height: 560px;
  max-height: 1920px;
  padding: 3vh 50px;
  height: 75vh;
  top: 5vh;
  flex: 1 1;
  position: relative;
  background: #141414e6 0% 0% no-repeat padding-box;
  margin: 0 auto;
  box-sizing: border-box;
  display: inline-block;
  pointer-events: all;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media only screen and (min-width: 576px) {
    .login-form-container {
      width: 375px;
      height: 75vh;
      top: 10vh; } }
  @media only screen and (min-width: 480px) {
    .login-form-container {
      width: 375px;
      height: 75vh;
      top: 10vh; } }
  @media only screen and (min-width: 768px) {
    .login-form-container {
      left: 0;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      width: 320px;
      margin: unset;
      top: 5vh;
      height: 90vh;
      padding: 3vh 30px; } }
  @media only screen and (min-width: 992px) {
    .login-form-container {
      left: 0;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      width: 340px;
      margin: unset;
      top: 10vh;
      height: 80vh; } }
  @media only screen and (min-width: 1200px) {
    .login-form-container {
      padding: 80px;
      width: 468px;
      top: 10vh;
      height: 80vh; } }
  @media only screen and (min-width: 1300px) {
    .login-form-container {
      padding: 80px;
      width: 468px;
      top: 10vh;
      height: 75vh;
      padding: 5vh 80px; } }
  .login-form-container .logo {
    width: 100%;
    height: 100px;
    margin-bottom: 1vh; }
  .login-form-container .title {
    font-weight: bold;
    color: #fff;
    font-size: 1.6vmin;
    margin-bottom: 0;
    justify-content: center;
    display: flex; }
  .login-form-container .description {
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1.4px;
    color: #ffffff;
    opacity: 0.7;
    font-weight: 300;
    margin-bottom: 4vh; }
    @media only screen and (min-width: 1300px) {
      .login-form-container .description {
        margin-bottom: 8vh; } }
    @media only screen and (min-width: 992px) {
      .login-form-container .description {
        margin-bottom: 6vh; } }
    @media only screen and (min-width: 768px) {
      .login-form-container .description {
        margin-bottom: 4vh; } }
  .login-form-container .links {
    margin-top: 1.2vh;
    color: lightgray;
    font-size: 12px;
    font-style: italic; }
    .login-form-container .links a {
      color: #fff;
      opacity: 0.6; }
  .login-form-container .fb-login {
    width: 95%;
    height: 40px; }
  .login-form-container .gg-login {
    display: flex;
    flex-direction: row-reverse; }
    .login-form-container .gg-login button {
      width: 95%;
      height: 40px; }
  .login-form-container .login-submit {
    margin-top: 2vh;
    width: 100%;
    height: 40px;
    background-color: #24BB00;
    border-color: #24BB00;
    color: #fff;
    font-weight: bold;
    font-size: 14px; }
    .login-form-container .login-submit:hover {
      background-color: #209005;
      border-color: #209005;
      color: #fff; }
  .login-form-container .ant-input-affix-wrapper .ant-input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #ffffff50;
    padding-left: 0 !important;
    border-radius: 0;
    outline: none;
    font-size: 14px;
    font-weight: 300;
    color: #fff; }
    .login-form-container .ant-input-affix-wrapper .ant-input:focus {
      box-shadow: none;
      border-bottom: 1px solid #40a9ff; }
  .login-form-container .ant-input-affix-wrapper .ant-input-prefix {
    display: none; }
  .login-form-container .has-error .ant-input-affix-wrapper .ant-input {
    background-color: transparent !important; }
    .login-form-container .has-error .ant-input-affix-wrapper .ant-input:hover, .login-form-container .has-error .ant-input-affix-wrapper .ant-input:focus {
      box-shadow: none;
      background-color: transparent !important; }

.input {
  border: none;
  border-bottom: 2px;
  border-bottom-color: white; }

.support_link {
  color: lightgray; }

.login-form-container .form-title {
  font-weight: bold;
  color: #fff;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 7vh; }

.login-form-container .register-form .back-button {
  margin: 1rem 0;
  color: #ffffff90;
  text-align: center;
  display: block;
  font-style: italic; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.m-0 {
  margin: 0 !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.m-1 {
  margin: 0.25rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.m-3 {
  margin: 1rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.m-5 {
  margin: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.p-1 {
  padding: 0.25rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.p-3 {
  padding: 1rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.p-5 {
  padding: 3rem !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .p-sm-5 {
    padding: 3rem !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .p-md-5 {
    padding: 3rem !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .p-lg-5 {
    padding: 3rem !important; } }

html {
  scroll-behavior: smooth; }
  html body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  html code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.container {
  max-width: 1380px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px; }
  @media only screen and (min-width: 768px) {
    .container {
      padding-left: 1rem;
      padding-right: 1rem; } }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ant-layout-sider-zero-width-trigger {
  top: 50px !important; }

.fluid-container {
  max-width: 1920px;
  margin: 0 auto; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes progress-width {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@keyframes progress-width {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.scroll-page {
  position: fixed;
  bottom: 0;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .scroll-page .image-wrap {
    text-align: center;
    height: 35px;
    overflow: hidden; }
    .scroll-page .image-wrap img {
      -webkit-animation: spin 8s linear infinite;
      animation: spin 8s linear infinite; }
  .scroll-page p {
    text-align: center;
    font-size: 12px;
    color: #fff;
    opacity: 0.6;
    margin-bottom: 0;
    text-transform: uppercase; }

.landing-page {
  background-color: #2c2c2c; }
  .landing-page .page-scroll {
    position: relative;
    min-height: 100vh;
    height: 100%; }
    .landing-page .page-scroll.page-scroll-padding {
      background-color: #2c2c2c; }
      .landing-page .page-scroll.page-scroll-padding.active {
        display: flex; }
  .landing-page .item-one {
    background: transparent url("/images/landing/page_1.png") center center no-repeat padding-box; }
  .landing-page .item-two {
    background: transparent url("/images/landing/bg_banner_gara.png") center center no-repeat padding-box; }
  .landing-page .item-three {
    background: transparent url("/images/landing/page_1_3.jpg") center center no-repeat padding-box; }
  .landing-page .carousel-item {
    height: 100vh;
    border: 1px solid #707070;
    background-size: cover;
    position: relative;
    z-index: 1; }
    .landing-page .carousel-item:after {
      background-color: #41414160;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 2;
      position: absolute;
      top: 0; }
    .landing-page .carousel-item .container {
      position: relative; }
      .landing-page .carousel-item .container .item-detail {
        display: none;
        position: absolute;
        top: 15vh;
        z-index: 9;
        max-width: 100%; }
        @media only screen and (min-width: 768px) {
          .landing-page .carousel-item .container .item-detail {
            padding-left: calc(320px + 6vw);
            display: block; } }
        @media only screen and (min-width: 1300px) {
          .landing-page .carousel-item .container .item-detail {
            padding-left: calc(468px + 5vw);
            display: block; } }
        @media only screen and (min-width: 1200px) {
          .landing-page .carousel-item .container .item-detail {
            padding-left: calc(468px + 5vw);
            display: block; } }
        .landing-page .carousel-item .container .item-detail .number {
          margin-bottom: 5vh;
          font-weight: bold;
          color: #fff;
          font-size: 24px;
          opacity: 1;
          position: relative; }
          @media only screen and (min-width: 992px) {
            .landing-page .carousel-item .container .item-detail .number {
              font-size: 26px; } }
          .landing-page .carousel-item .container .item-detail .number .progress {
            display: inline-block;
            width: 120px;
            height: 3px;
            top: -7px;
            left: 12px;
            position: relative; }
            .landing-page .carousel-item .container .item-detail .number .progress .carousel-progress {
              position: absolute;
              height: 3px !important;
              background-color: #fff;
              opacity: 1;
              -webkit-animation: progress-width 4.1s ease-in-out;
                      animation: progress-width 4.1s ease-in-out;
              -webkit-animation-fill-mode: forwards;
                      animation-fill-mode: forwards; }
        .landing-page .carousel-item .container .item-detail .sub-title {
          color: #fff;
          font-size: 22px;
          font-weight: 300;
          margin-bottom: 1rem; }
          @media only screen and (min-width: 992px) {
            .landing-page .carousel-item .container .item-detail .sub-title {
              font-size: 18px; } }
        .landing-page .carousel-item .container .item-detail .title {
          color: #fff;
          font-size: 36px;
          font-weight: bold;
          text-transform: none;
          line-height: 1.2;
          margin-left: -3px;
          margin-bottom: 2rem;
          padding-right: 2rem;
          text-shadow: 2px 2px 10px #000000; }
          @media only screen and (min-width: 1200px) {
            .landing-page .carousel-item .container .item-detail .title {
              font-size: 68px; } }
        .landing-page .carousel-item .container .item-detail .description {
          color: #fff;
          font-size: 14px;
          text-shadow: 2px 2px 10px #000000;
          letter-spacing: 0px;
          padding-right: 40px; }
          @media only screen and (min-width: 1200px) {
            .landing-page .carousel-item .container .item-detail .description {
              font-size: 18px; } }
          @media only screen and (min-width: 992px) {
            .landing-page .carousel-item .container .item-detail .description {
              font-size: 16px; } }
        .landing-page .carousel-item .container .item-detail .apps-download {
          margin-top: 2rem;
          margin-bottom: 0.5rem; }
          @media only screen and (min-width: 768px) {
            .landing-page .carousel-item .container .item-detail .apps-download {
              margin-top: 2.5rem; } }
          .landing-page .carousel-item .container .item-detail .apps-download a {
            display: inline-block;
            width: 140px;
            margin-right: 1.5rem; }
  .landing-page .page-scroll-padding {
    justify-content: center;
    flex-direction: column; }
    .landing-page .page-scroll-padding * {
      color: #fff; }
    .landing-page .page-scroll-padding .ant-row {
      display: flex; }
    .landing-page .page-scroll-padding .col-image {
      text-align: center; }
      .landing-page .page-scroll-padding .col-image img {
        max-width: 75%;
        margin: 0 auto;
        display: inline-block; }
        @media only screen and (min-width: 992px) {
          .landing-page .page-scroll-padding .col-image img {
            max-width: 100%; } }
    .landing-page .page-scroll-padding .col-text {
      text-align: center;
      display: flex;
      align-items: center; }
      @media only screen and (min-width: 992px) {
        .landing-page .page-scroll-padding .col-text {
          text-align: right; } }
      .landing-page .page-scroll-padding .col-text .title {
        flex-flow: wrap;
        white-space: normal;
        font-size: 40px;
        text-transform: none; }
      .landing-page .page-scroll-padding .col-text .description {
        font-size: 14px;
        margin: 2rem 0; }
      .landing-page .page-scroll-padding .col-text .btn {
        margin-top: 2rem;
        font-size: 24px;
        min-width: 245px;
        padding: 7px 0;
        border: 1px solid #fff;
        font-weight: bold;
        background-color: transparent;
        border-radius: 4px; }
        .landing-page .page-scroll-padding .col-text .btn:hover {
          border-color: green;
          cursor: pointer; }
    @media only screen and (min-width: 992px) {
      .landing-page .page-scroll-padding:nth-child(2n + 1) .col-text {
        text-align: left; } }
    .landing-page .page-scroll-padding:nth-child(2n + 1) .ant-col:first-child {
      order: 1; }
    .landing-page .page-scroll-padding:nth-child(2n + 1) .ant-col:last-child {
      order: 0; }
    @media only screen and (min-width: 992px) {
      .landing-page .page-scroll-padding:nth-child(2n + 1) .ant-col:first-child {
        order: 0; }
      .landing-page .page-scroll-padding:nth-child(2n + 1) .ant-col:last-child {
        order: 1; } }
    .landing-page .page-scroll-padding.our-commit {
      background-image: url("/images/landing/page_5.png");
      background-size: contain;
      padding: -3rem 0;
      position: relative;
      background-position-y: bottom;
      background-repeat: no-repeat; }
      .landing-page .page-scroll-padding.our-commit .container {
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        justify-content: space-around; }
      .landing-page .page-scroll-padding.our-commit:after {
        background: -webkit-gradient(linear, left top, left bottom, from(#2c2c2c), color-stop(rgba(44, 44, 44, 0.8)), to(rgba(44, 44, 44, 0.1)));
        background: linear-gradient(#2c2c2c, rgba(44, 44, 44, 0.8), rgba(44, 44, 44, 0.1));
        background: linear-gradient(#2c2c2c, rgba(44, 44, 44, 0.8), rgba(44, 44, 44, 0.1));
        position: absolute;
        content: "";
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .landing-page .page-scroll-padding.our-commit .title {
        font-size: 32px;
        margin-bottom: 0.5rem; }
        @media only screen and (min-width: 992px) {
          .landing-page .page-scroll-padding.our-commit .title {
            font-size: 40px;
            margin-bottom: 1rem; } }
      .landing-page .page-scroll-padding.our-commit .commit .commit-title {
        font-size: 18px;
        margin: 0.6rem 0 0.6rem 0; }
        @media only screen and (min-width: 992px) {
          .landing-page .page-scroll-padding.our-commit .commit .commit-title {
            font-size: 24px; } }
      .landing-page .page-scroll-padding.our-commit .commit .description {
        font-size: 14px; }
      .landing-page .page-scroll-padding.our-commit .footer h2 {
        font-size: 35px;
        margin-bottom: 0.2rem;
        line-height: 1.3;
        text-transform: uppercase;
        font-weight: bold; }
  .landing-page .login-ui {
    z-index: 10;
    position: absolute;
    width: 468px;
    margin-left: 8vw; }
    @media only screen and (min-width: 300px) {
      .landing-page .login-ui {
        width: 90%;
        margin-left: auto;
        margin-right: auto; } }
    @media only screen and (min-width: 480px) {
      .landing-page .login-ui {
        width: 90%;
        margin-left: auto;
        margin-right: auto; } }
    @media only screen and (min-width: 576px) {
      .landing-page .login-ui {
        width: 90%;
        margin-left: auto;
        margin-right: auto; } }
    @media only screen and (min-width: 768px) {
      .landing-page .login-ui {
        width: 468px;
        margin-left: 5vw; } }
    @media only screen and (min-width: 992px) {
      .landing-page .login-ui {
        width: 468px;
        margin-left: 5vw; } }
    @media only screen and (min-width: 1600px) {
      .landing-page .login-ui {
        width: 468px;
        margin-left: 8vw; } }
  .landing-page .commit-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px !important; }
    .landing-page .commit-container .commit-box {
      width: 100%;
      padding: 20px;
      margin-bottom: 0px !important; }
      @media only screen and (min-width: 576px) {
        .landing-page .commit-container .commit-box {
          width: auto;
          flex: 1 1;
          margin-bottom: 0px !important; } }

.body {
  background-color: #111f10;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif; }

#expanded_search {
  align-items: center;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #1c1c1c;
  justify-content: center;
  display: flex;
  padding: 15px; }
  #expanded_search .search-box {
    width: 100%;
    max-height: 50px;
    height: 5vh;
    background: #383838 0 0 no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    position: relative;
    display: flex; }
    #expanded_search .search-box .search-input {
      width: 90%;
      height: 100%;
      background: transparent;
      border: none;
      color: white; }
    #expanded_search .search-box .search-icon {
      color: white;
      position: absolute;
      right: 0;
      height: 100%;
      width: auto;
      font-size: 24px;
      padding: 8px; }

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 100%;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse; }
  #header .header-wrapper {
    display: flex;
    height: 100%;
    color: white;
    align-items: center;
    max-width: 100%; }
    @media only screen and (min-width: 768px) {
      #header .header-wrapper {
        max-width: 60%; } }
  #header .logo {
    fill: white;
    width: 100%;
    height: 100px; }
  #header .headerLogo {
    fill: white;
    width: 56px;
    height: 100%;
    background-color: #1c1c1c;
    padding: 10px; }
  #header .search-wrapper {
    width: 30%;
    min-width: 200px;
    justify-content: center;
    display: flex; }
    #header .search-wrapper .search-box {
      max-width: 300px;
      width: 100%;
      max-height: 50px;
      height: 5vh;
      background: #383838 0 0 no-repeat padding-box;
      border-radius: 12px;
      opacity: 1;
      position: relative;
      display: flex;
      margin-right: 5px; }
      #header .search-wrapper .search-box .search-input {
        width: 90%;
        height: 100%;
        background: transparent;
        border: none;
        color: white; }
      #header .search-wrapper .search-box .search-icon {
        color: white;
        position: absolute;
        right: 0;
        height: 100%;
        width: auto;
        font-size: 24px;
        padding: 8px; }
  #header .profile-box {
    display: flex;
    min-width: 300px;
    height: 100%;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: right;
    background-color: #1c1c1c; }
    @media only screen and (min-width: 576px) {
      #header .profile-box {
        width: 50%;
        justify-content: right;
        background-color: transparent; } }
    @media only screen and (min-width: 992px) {
      #header .profile-box {
        width: 30%;
        justify-content: right; } }
    #header .profile-box .badge-wrapper .ant-badge {
      vertical-align: middle; }
      @media only screen and (min-width: 576px) {
        #header .profile-box .badge-wrapper .ant-badge {
          margin: 0 1rem; } }
      @media only screen and (min-width: 1300px) {
        #header .profile-box .badge-wrapper .ant-badge {
          margin: 0 2rem; } }
      #header .profile-box .badge-wrapper .ant-badge:first-child {
        margin-right: 0.68rem; }
        @media only screen and (min-width: 576px) {
          #header .profile-box .badge-wrapper .ant-badge:first-child {
            margin-right: 1rem; } }
      #header .profile-box .badge-wrapper .ant-badge:last-child {
        margin-left: 0.68rem;
        margin-right: 1rem; }
        @media only screen and (min-width: 576px) {
          #header .profile-box .badge-wrapper .ant-badge:last-child {
            margin-left: 1rem; } }
      #header .profile-box .badge-wrapper .ant-badge i {
        font-size: 20px;
        margin-top: 5px;
        color: #fff; }
      #header .profile-box .badge-wrapper .ant-badge .ant-badge-count {
        top: 6px;
        min-width: 18px;
        height: 18px;
        line-height: 18px;
        background: #24BB00;
        border-radius: 9px;
        box-shadow: none; }
        #header .profile-box .badge-wrapper .ant-badge .ant-badge-count .ant-scroll-number-only {
          height: 18px; }
          #header .profile-box .badge-wrapper .ant-badge .ant-badge-count .ant-scroll-number-only > p {
            height: 18px;
            margin: 0;
            font-weight: bold;
            font-size: 10px; }
    #header .profile-box .mobile-header-extra {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 4px; }
      @media only screen and (min-width: 576px) {
        #header .profile-box .mobile-header-extra {
          display: none; } }
    #header .profile-box .profile-dropdown {
      height: 100%;
      margin-right: 15px; }
      @media only screen and (min-width: 576px) {
        #header .profile-box .profile-dropdown {
          margin-right: 0px; } }
      #header .profile-box .profile-dropdown .profile-dropdown-list {
        background-color: #1c1c1c;
        display: flex;
        align-items: center; }
        #header .profile-box .profile-dropdown .profile-dropdown-list .profile-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 10px; }
          @media only screen and (min-width: 576px) {
            #header .profile-box .profile-dropdown .profile-dropdown-list .profile-info {
              margin-left: 30px; } }
          #header .profile-box .profile-dropdown .profile-dropdown-list .profile-info .profile-name {
            color: white;
            font-weight: bold;
            font-size: 1em;
            line-height: 16px; }
          #header .profile-box .profile-dropdown .profile-dropdown-list .profile-info .profile-coin {
            font-weight: bolder;
            font-size: 1em;
            line-height: 16px;
            text-align: right; }
        #header .profile-box .profile-dropdown .profile-dropdown-list .setting {
          display: none;
          margin-left: 20px;
          margin-right: 20px;
          font-size: 2.5vmin; }
          @media only screen and (min-width: 576px) {
            #header .profile-box .profile-dropdown .profile-dropdown-list .setting {
              display: block; } }
  #header .sub_header_menu_box {
    background: transparent; }
  #header .sub_header_menu_item {
    background: transparent; }

.nav {
  display: flex;
  height: 30px;
  align-items: center;
  color: lightgray;
  font-size: 12px;
  flex-wrap: wrap; }
  .nav .home-link {
    padding-left: 8px;
    color: white;
    line-height: 18px; }
  .nav .menu-link {
    padding-left: 15px;
    line-height: 18px; }

.menu-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .menu-wrapper .menu-border {
    height: 2px;
    width: 30px;
    background-color: green;
    margin-bottom: 8px;
    margin-left: 0; }

#footer {
  text-align: center;
  background-color: #000;
  color: #fff; }

.avatar_small {
  width: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  border: 0.7vmin solid rgba(255, 255, 255, 0.3); }

.center_col {
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  padding: 5px; }

.zoom_image {
  z-index: 0;
  flex: 1 1;
  margin: 10px;
  max-width: 512px;
  border: solid white 1px; }

.zoom_image div > div > img {
  z-index: 1 !important;
  position: relative; }

.zoom_image div > div > div > img {
  z-index: 0 !important;
  max-width: none !important; }

.page-user-intro .bg {
  background-color: #191919b3;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  height: 125px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0; }
  @media only screen and (min-width: 768px) {
    .page-user-intro .bg {
      height: 230px; } }

.page-user-intro .content {
  padding-top: 90px;
  margin: 0 auto;
  height: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  @media only screen and (min-width: 768px) {
    .page-user-intro .content {
      padding-top: 145px;
      padding-left: 10px !important;
      padding-right: 10px !important; } }

.page-user-intro .user-wrapper {
  border-bottom: 1px solid #4A4A4A;
  display: flex;
  flex-wrap: wrap; }
  .page-user-intro .user-wrapper .user-tabs {
    display: flex;
    flex: 1 1;
    margin-top: 0px; }
    @media only screen and (min-width: 768px) {
      .page-user-intro .user-wrapper .user-tabs {
        margin-top: 85px; } }
  .page-user-intro .user-wrapper .user-info {
    display: flex;
    flex: 1 1;
    padding-left: 15px !important;
    padding-right: 8px !important;
    margin-bottom: 15px;
    min-width: 320px;
    z-index: 1; }
    @media only screen and (min-width: 768px) {
      .page-user-intro .user-wrapper .user-info {
        margin-bottom: 0px;
        margin-top: 1rem;
        padding-left: 10px; } }
    .page-user-intro .user-wrapper .user-info .title {
      font-size: 1.5em;
      margin-top: 2rem;
      font-weight: bold;
      color: #fff;
      line-height: 30px;
      margin-bottom: 8px; }
      @media only screen and (min-width: 768px) {
        .page-user-intro .user-wrapper .user-info .title {
          font-size: 1.9em; } }
      .page-user-intro .user-wrapper .user-info .title .level {
        margin-left: 10px;
        padding: 1px 10px;
        vertical-align: bottom; }
  .page-user-intro .user-wrapper .user-detail {
    width: 100%;
    margin-left: 20px;
    z-index: 9999; }
    @media only screen and (min-width: 768px) {
      .page-user-intro .user-wrapper .user-detail {
        padding-top: 1.6rem;
        margin-bottom: 5px; } }
    .page-user-intro .user-wrapper .user-detail .balance {
      padding-top: 0.6rem; }
      @media only screen and (min-width: 768px) {
        .page-user-intro .user-wrapper .user-detail .balance {
          padding-top: 1.4rem; } }
      .page-user-intro .user-wrapper .user-detail .balance .label {
        color: #ffffff60;
        font-weight: bold;
        font-size: 14px; }
        .page-user-intro .user-wrapper .user-detail .balance .label i {
          font-size: 20px;
          margin-right: 8px; }
        .page-user-intro .user-wrapper .user-detail .balance .label .symbol {
          font-weight: normal;
          position: relative;
          top: -1px;
          right: -7px; }
      .page-user-intro .user-wrapper .user-detail .balance .price {
        color: #24BB00;
        font-size: 1.6em;
        font-weight: bold;
        line-height: 1.2;
        margin-right: 20px; }
        @media only screen and (min-width: 768px) {
          .page-user-intro .user-wrapper .user-detail .balance .price {
            font-size: 2.1em; } }

.tab-heading {
  width: 25%;
  text-align: center;
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff40;
  border-left: 1px solid #4A4A4A;
  position: relative;
  padding: 7px; }
  .tab-heading .icon {
    font-size: 1.2em; }
  @media only screen and (min-width: 768px) {
    .tab-heading {
      padding: 0px; } }
  .tab-heading:first-child {
    border-left: 0; }
  .tab-heading.active, .tab-heading:hover {
    cursor: pointer;
    color: #fff;
    border-bottom: 4px solid #24BB00; }
  .tab-heading .heading {
    margin-top: 2px;
    height: 0px;
    visibility: hidden; }
    @media only screen and (min-width: 768px) {
      .tab-heading .heading {
        visibility: visible;
        height: auto; } }
  .tab-heading .sub-heading {
    font-size: 12px;
    color: #FFCE0060;
    font-style: italic;
    position: absolute;
    bottom: 2px; }
  .tab-heading.coming-soon i {
    color: #FFFFFF20; }
  .tab-heading.coming-soon .heading {
    color: #FFFFFF20; }
  .tab-heading.coming-soon:hover {
    cursor: no-drop;
    border-bottom: none; }

.price {
  color: #fff;
  font-weight: bold;
  position: relative; }
  .price .symbol {
    font-size: 12px;
    color: #ffffff40;
    position: absolute;
    top: 0;
    left: calc(100% + 4px);
    text-transform: uppercase; }

.cart {
  color: white;
  background-color: #2c2c2c;
  height: auto;
  min-height: 100vh;
  position: relative; }
  .cart .cart-top .heading {
    color: #ffffff90;
    font-size: 14px;
    margin-top: 10px;
    text-transform: none;
    margin-bottom: 0; }
  .cart .cart-top .title {
    color: #18ce36;
    font-size: 24px; }
    .cart .cart-top .title .price {
      color: #18ce36;
      font-size: 24px; }
  .cart .cart-top .delete-all-button {
    height: 40px;
    margin: 5px !important;
    flex: 1 1;
    background-color: transparent;
    border: 2px solid #cc0000;
    color: #cc0000;
    position: absolute;
    right: 0;
    top: 10px; }
    .cart .cart-top .delete-all-button:hover {
      background-color: #cc0000;
      color: white;
      cursor: pointer; }
  .cart .right-button-wrap {
    flex: 0 1 100%; }
    @media only screen and (min-width: 480px) {
      .cart .right-button-wrap {
        flex: 0 1 100px; } }
  .cart .cart-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
    .cart .cart-wrapper .cart-inner {
      flex: 6 1;
      margin-top: 15px;
      overflow-x: scroll; }
      @media only screen and (min-width: 768px) {
        .cart .cart-wrapper .cart-inner {
          margin-top: 0px; } }
    .cart .cart-wrapper .cart-detail {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 1px solid #707070;
      padding-bottom: 1rem; }
      @media screen and (max-width: 640px) {
        .cart .cart-wrapper .cart-detail {
          width: 100%;
          overflow-x: auto; } }
  .cart .cart-header {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    font-weight: bold;
    color: white;
    font-size: 12px;
    letter-spacing: 0;
    padding-bottom: 5px; }
    @media only screen and (min-width: 768px) {
      .cart .cart-header {
        font-size: 16px; } }
    .cart .cart-header .text-center {
      text-align: center; }
    .cart .cart-header .text-right {
      text-align: right; }
  .cart .cart-row {
    width: 100%;
    border-radius: 6px;
    background-color: #262626e6;
    margin-top: 5px;
    padding: 5px 0 3px 0;
    display: flex; }
    .cart .cart-row .ant-col {
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden; }
      .cart .cart-row .ant-col:first-child, .cart .cart-row .ant-col:last-child {
        padding-left: 4px;
        padding-right: 4px; }
    .cart .cart-row .product-ref {
      font-size: 9px;
      padding-right: 2px;
      color: lightgray; }
      @media only screen and (min-width: 768px) {
        .cart .cart-row .product-ref {
          font-size: 12px; } }
    .cart .cart-row .product-name {
      font-weight: bold;
      color: white;
      font-size: 11px;
      margin-bottom: 0;
      line-height: 1; }
      @media only screen and (min-width: 768px) {
        .cart .cart-row .product-name {
          font-size: 14px; } }
    .cart .cart-row .product-sku {
      text-align: left;
      font-size: 10px;
      color: #ffffff66; }
    .cart .cart-row .product-price {
      text-align: left;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 4px; }
      @media only screen and (min-width: 768px) {
        .cart .cart-row .product-price {
          font-size: 16px;
          font-weight: bold;
          justify-content: flex-start; } }
      .cart .cart-row .product-price .symbol {
        font-size: 10px; }
      .cart .cart-row .product-price .price {
        font-weight: 400 !important; }
        @media only screen and (min-width: 768px) {
          .cart .cart-row .product-price .price {
            font-weight: bold; } }
    .cart .cart-row .decrease,
    .cart .cart-row .increase {
      font-size: 20px; }
      .cart .cart-row .decrease:hover,
      .cart .cart-row .increase:hover {
        cursor: pointer; }
    .cart .cart-row .product-total {
      text-align: right;
      padding-right: 25px;
      font-size: 16px; }
      .cart .cart-row .product-total .symbol {
        font-size: 10px; }
    .cart .cart-row .actions {
      justify-content: center; }
      .cart .cart-row .actions .delete-button {
        color: #cc0000; }
        .cart .cart-row .actions .delete-button:hover {
          cursor: pointer; }
  .cart .cart-actions .ant-col {
    padding-right: 1rem;
    margin-bottom: 1rem; }
    .cart .cart-actions .ant-col:last-child {
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      @media only screen and (min-width: 768px) {
        .cart .cart-actions .ant-col:last-child {
          align-items: flex-end; } }
  .cart .cart-actions .notes-and-conditions a {
    font-size: 12px;
    font-style: italic;
    color: #ffffff60;
    line-height: 1; }
  .cart .cart-actions .label span {
    font-size: 12px;
    color: #fff;
    background-color: #24BB00;
    position: relative;
    display: inline-block;
    padding: 1px 10px 0.5px;
    font-style: italic;
    line-height: 21px; }
    .cart .cart-actions .label span:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 0 solid transparent;
      border-right: 22px solid transparent;
      border-bottom: 19px solid #24bb00;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      right: -20px;
      top: 1px; }
  .cart .cart-actions .total-price-section {
    padding-right: 1.5rem; }
    .cart .cart-actions .total-price-section .price {
      font-size: 24px;
      display: inline-block;
      margin-top: 5px; }
  .cart .cart-actions .cart-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse !important;
    flex-wrap: wrap;
    align-items: center; }
    .cart .cart-actions .cart-buttons .ant-btn {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      height: 40px;
      border: none;
      font-weight: bold;
      display: block;
      margin: 5px;
      max-width: 200px; }
    .cart .cart-actions .cart-buttons .checkout-button {
      min-height: 40px;
      height: 40px;
      flex: 1 1;
      background-color: #24BB00;
      color: #fff;
      margin: 5px !important; }
      .cart .cart-actions .cart-buttons .checkout-button:hover {
        background-color: #105500; }
    .cart .cart-actions .cart-buttons .continue-button {
      height: 40px;
      margin: 5px !important;
      flex: 1 1;
      background-color: transparent;
      border: 2px solid #ffffff60;
      color: #ffffff60; }
      .cart .cart-actions .cart-buttons .continue-button:hover {
        color: #fff; }

.product-title {
  font-weight: bold;
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .product-title {
      font-size: 1em; } }

.product-title_vn {
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .product-title_vn {
      font-size: 0.88em; } }

.page-user-intro {
  margin-bottom: 1rem; }

.ant-tabs-bar {
  margin-bottom: 5px; }

.cart .cart-wrapper {
  z-index: 1; }
  .cart .cart-wrapper .cart-detail {
    border-bottom: 0; }
  .cart .cart-wrapper .ant-tabs-nav-wrap {
    margin-bottom: 0; }
  .cart .cart-wrapper .ant-tabs-nav {
    width: 100%; }
  .cart .cart-wrapper .ant-tabs-tab {
    margin: 0;
    padding: 0 16px 0 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff60;
    width: 50%;
    border-bottom: 3px solid #4A4A4A; }
    .cart .cart-wrapper .ant-tabs-tab:hover {
      color: #fff; }
    .cart .cart-wrapper .ant-tabs-tab.ant-tabs-tab-active {
      color: #fff;
      border-bottom: 3px solid #24BB00; }
    @media only screen and (min-width: 768px) {
      .cart .cart-wrapper .ant-tabs-tab {
        font-size: 24px; } }
  .cart .cart-wrapper .ant-tabs-ink-bar {
    height: 0; }

.cart-history .cart-header {
  color: #ffffff60;
  padding: 5px 0;
  font-size: 14px;
  border-bottom: none;
  margin-left: 0px;
  margin-right: 0px; }
  @media only screen and (min-width: 768px) {
    .cart-history .cart-header {
      margin-left: 14px;
      margin-right: 26px; } }

.cart-history .cart-row {
  color: #fff;
  font-size: 14px;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 7px;
  background-color: transparent; }
  .cart-history .cart-row .ant-col {
    background-color: #262626E6;
    justify-content: center;
    align-items: flex-start; }
    .cart-history .cart-row .ant-col.created-at, .cart-history .cart-row .ant-col.product-name-col {
      padding: 7px 0; }
    .cart-history .cart-row .ant-col > div {
      height: 100% !important; }
    .cart-history .cart-row .ant-col .center_col {
      padding: 0; }
  .cart-history .cart-row .created-time {
    color: #ffffff60;
    font-size: 12px;
    line-height: 1.3; }
  .cart-history .cart-row .created-date {
    font-weight: bold;
    line-height: 1.3; }
  .cart-history .cart-row .product-name {
    line-height: 1.2; }
  .cart-history .cart-row .product-price {
    font-weight: 700;
    padding: 0 1rem;
    background-color: #000;
    align-items: flex-end;
    justify-content: center;
    display: flex; }
    .cart-history .cart-row .product-price .price {
      font-size: 16px; }
  .cart-history .cart-row .actions {
    padding-left: 0 !important;
    background-color: transparent; }
    .cart-history .cart-row .actions .button-cart {
      background-color: #24BB00;
      border-radius: 0 6px 6px 0;
      justify-content: center;
      align-items: center;
      display: flex; }
      .cart-history .cart-row .actions .button-cart:hover {
        background-color: #1a8800;
        cursor: pointer; }
      .cart-history .cart-row .actions .button-cart i {
        font-size: 18px;
        padding: 0 1rem; }

.level {
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  border-radius: 4px;
  padding: 1px 10px; }
  .level.level1 {
    background-color: #FFCE0090; }
  .level.level2 {
    background-color: #FF620077; }
  .level.level3 {
    background-color: #FF0A0077; }

.user-level {
  color: #FFFFFF80; }
  .user-level .ant-row {
    margin-bottom: 0.5rem; }
  .user-level .title {
    font-weight: bold; }
  .user-level strong {
    color: #ffffff90; }
  .user-level .heading {
    border-bottom: 2px solid #4A4A4A;
    padding: 0 16px 0 0;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase; }


.product_part_header__Ed6qK {
    border-bottom: 2px solid rgba(255,255,255,0.2);
    height: 30px;
    font-weight: bold;
    color:white;
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 4px;
}
.product_group_name__12Gnu {
    text-align: left;
    letter-spacing: 0.58px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    line-height: 37px;
    height: 54px;
}
.product_title__2rsSi {
    font-weight: bold;
    color:white;
    font-size: 0.9em;
    letter-spacing: 0;
}
.product_title_vn__3syVh {
    color:white;
    font-size: 0.88em;
    letter-spacing: 0;
}
.product_part_name__RCxnY {
    font-weight: bold;
    color:white;
    font-size: 1.1vmin;
    letter-spacing: 0;

}
.product_new_code__2IhzB{
    text-align: left;
    font-size: 0.72em;
    letter-spacing: 0;
    color: #FFCE00C4;
}
.product_old_code__3bRMF{
    text-align: left;
    font-size: 0.72em;
    letter-spacing: 0;
    color: #FFFFFF66;
    text-decoration: line-through;
}
.product_code__2S7VS{
    text-align: left;
    font-size: 0.72em;
    letter-spacing: 0;
    color: #FFFFFF66;
}
.product_inventory__3d0Qu {
    text-align: right;
    font-size: 19px;
    letter-spacing: 0;
    padding-right: 5px;
    min-width: 30px;
}
.product_part_location__1LdSq {
    position: absolute;
    right: 100px;
    top: 0px;
    height: 100%;
    align-items: center;
    display: flex;
}
.product_name_box__ytnaS {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding:5px;
    align-items: center;
}
.product_shopping_cart__21dUB {
    background: #24BB00 0% 0% no-repeat padding-box;
    border-radius: 0px 6px 6px 0px;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display:flex;
}
.product_shopping_cart_disable__MGB4R {
    background: rgb(61, 61, 61) 0% 0% no-repeat padding-box;
    border-radius: 0px 6px 6px 0px;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display:flex;
}
.product_part_item_box__3gaQC {
    width: 100%;
    height: 40px;
    border-radius: 6px; 
    background-color: #262626E6;
    margin-top:5px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-bottom: 0px;
}
.product_part_item_col__IXXtu {
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display:flex;
    padding:5px;
}
.page-intro .bg {
  background-color: #191919B3;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  height: 180px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0; }
  @media only screen and (min-width: 768px) {
    .page-intro .bg {
      height: 230px; } }

.page-intro .content {
  padding-top: 90px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  @media only screen and (min-width: 768px) {
    .page-intro .content {
      padding-top: 140px; } }

.page-intro .title {
  font-size: 40px;
  font-weight: bold;
  z-index: 1;
  color: #fff; }
  @media only screen and (min-width: 768px) {
    .page-intro .title {
      font-size: 50px; } }
  @media only screen and (min-width: 992px) {
    .page-intro .title {
      font-size: 50px; } }

.section-heading {
  border-bottom: 1px solid #707070;
  margin-bottom: 1rem; }
  .section-heading .title {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .section-heading .view-more-wrapper {
    position: absolute;
    right: 0;
    top: 15px;
    background-color: #24BB00; }
    .section-heading .view-more-wrapper:hover {
      background-color: #156f00;
      color: #fff; }
      .section-heading .view-more-wrapper:hover .view-more:before {
        border-bottom: 19px solid #156f00; }
    .section-heading .view-more-wrapper .view-more {
      font-size: 12px;
      padding: 3px 7px;
      color: #fff;
      position: relative; }
      .section-heading .view-more-wrapper .view-more:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 0 solid transparent;
        border-right: 21px solid transparent;
        border-bottom: 19px solid #24BB00;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
        left: -20px;
        top: 0; }

.feature-part {
  padding-top: 0.5rem;
  padding-bottom: 88px; }
  .feature-part .img {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 8px; }
  .feature-part .title {
    margin: 0.5rem 0;
    font-size: 18px;
    color: #fff; }
  .feature-part .sku {
    font-style: italic;
    font-size: 14px; }
  .feature-part .price-section {
    border-top: 1px solid #707070;
    padding-top: 5px;
    color: green;
    font-weight: bold;
    margin-bottom: 10px; }
    .feature-part .price-section .price {
      font-size: 14px;
      color: #24BB00; }
  .feature-part .d-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.latest-part {
  padding-top: 0.5rem;
  padding-bottom: 88px; }
  .latest-part .img {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 8px; }
  .latest-part .title {
    margin: 0.5rem 0;
    text-transform: none;
    color: #fff; }
  .latest-part .short-description {
    border-bottom: 1px solid #707070;
    padding-bottom: 5px; }
  .latest-part .avatar {
    margin-right: 8px; }

.home-page {
  position: relative;
  color: #fff;
  background-color: #2C2C2C; }
  .home-page .title {
    font-weight: 700; }
  .home-page .cover {
    width: 100%;
    position: absolute;
    top: 0;
    background-size: cover;
    height: 300px; }
    @media only screen and (min-width: 576px) {
      .home-page .cover {
        height: 350px; } }
  .home-page .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 45px; }
    @media only screen and (min-width: 576px) {
      .home-page .container {
        padding-top: 90px; } }
  .home-page .page-intro .title {
    z-index: 1;
    height: 80px;
    color: white;
    font-size: 2.1em;
    display: flex;
    justify-content: center;
    font-weight: bolder; }
  .home-page .page-intro .description {
    z-index: 1;
    word-wrap: wrap;
    width: auto;
    height: 145px;
    max-width: 600px;
    text-align: center;
    margin-top: 10px;
    font-size: 1em; }

.site-collapse-custom-collapse .ant-collapse-header {
  position: relative;
  padding: 0px 0px !important;
  padding-left: 2px !important;
  color: white !important;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 0px !important;
  color: white !important;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.bikes-info {
  display: flex;
  align-content: center;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly; }
  @media only screen and (min-width: 768px) {
    .bikes-info {
      visibility: hidden; } }

.search-header {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  height: 30px;
  font-weight: bold;
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  margin-bottom: 4px;
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  background-color: #2c2c2c;
  z-index: 11;
  height: 40px; }
  @media only screen and (min-width: 768px) {
    .search-header {
      font-size: 14px; } }

.search-header-column {
  align-items: center;
  display: flex;
  height: 100%; }

.new_code {
  text-align: left;
  font-size: 9px;
  letter-spacing: 0;
  color: #ffce00c4; }
  @media only screen and (min-width: 768px) {
    .new_code {
      font-size: 11px; } }

.old_code {
  text-align: left;
  font-size: 9px;
  letter-spacing: 0;
  color: #ffffff66;
  text-decoration: line-through; }
  @media only screen and (min-width: 768px) {
    .old_code {
      font-size: 11px; } }

.code {
  text-align: left;
  font-size: 9px;
  letter-spacing: 0;
  color: #ffffff66; }
  @media only screen and (min-width: 768px) {
    .code {
      font-size: 11px; } }

.title {
  font-weight: bold;
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media only screen and (min-width: 576px) {
    .title {
      font-size: 13px; } }
  @media only screen and (min-width: 768px) {
    .title {
      font-size: 13px; } }

.sub-title {
  font-weight: 400;
  color: lightgray;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media only screen and (min-width: 768px) {
    .sub-title {
      font-size: 13px; } }

.ref-code {
  height: 100%;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 9px;
  padding-left: 5px; }
  @media only screen and (min-width: 768px) {
    .ref-code {
      font-size: 11px;
      line-height: 11px; } }

.inventory {
  text-align: center;
  font-size: 19px;
  letter-spacing: 0;
  padding-right: 5px;
  min-width: 50px; }

.paths {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 28px; }
  @media only screen and (min-width: 768px) {
    .paths {
      font-size: 15px; } }

.ant-table-pagination.ant-pagination {
    float: right;
    margin: 8px 0;
}
.ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    margin-bottom: 16px !important;
    vertical-align: top;
}
.ant-layout-header {
    /* height: 64px; */
    padding: 0 0px !important;
    line-height: 64px;
    background: #2c2c2c;
}
.ant-menu-horizontal {
    line-height: 64px;
    height: 100%;
    white-space: nowrap;
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    box-shadow: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 15px ;
    color: white !important;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #383838;
    border-top: 0;
}
.ant-collapse-content {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    background-color: #383838 ;
    border-top: 1px solid #383838 ;
}
.ant-modal-footer {
    background-color: black;
    padding:0px;
}
.ant-modal-header {
    padding: 16px 24px;
    color: white;
    background: black;
    border-bottom: none !important;
    text-align: center;
}
.ant-modal-title {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    word-wrap: break-word;
}
.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: black;
    border-top: none !important;
    border-radius: 0 0 4px 4px;
}
.ant-form-item-label > label{
    color:white;
}
