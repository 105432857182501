.latest-part {
    padding-top: 0.5rem;
    padding-bottom: 88px;
    .img {
        width: 100%;
        margin-bottom: 0.5rem;
        border-radius: 8px;
    }

    .title {
        margin: 0.5rem 0;
        text-transform: none;
        color: #fff;
    }

    .short-description {
        border-bottom: 1px solid rgb(112, 112, 112);
        padding-bottom: 5px;
    }

    .avatar {
        margin-right: 8px;
    }
}
