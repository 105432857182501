@import "../../../../resources/scss/variable";

.section-heading {
  border-bottom: 1px solid rgb(112, 112, 112);
  margin-bottom: 1rem;

  .title {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .view-more-wrapper {
    position: absolute;
    right: 0;
    top: 15px;
    background-color: $primary-color;

    &:hover {
      background-color: darken($primary-color, 15%);
      color: #fff;

      .view-more:before {
        border-bottom: 19px solid darken($primary-color, 15%);
      }
    }

    .view-more {
      font-size: 12px;
      padding: 3px 7px;
      color: #fff;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 0 solid transparent;
        border-right: 21px solid transparent;
        border-bottom: 19px solid #24BB00;
        transform: rotate(270deg);
        left: -20px;
        top: 0;
      }
    }
  }
}