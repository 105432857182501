@import '../../../../resources/scss/mixin';

.page-intro {
  .bg {
    background-color: #191919B3;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    height: 180px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    @include responsive(md) {
      height: 230px;
    }
  }

  .content {
    padding-top: 90px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include responsive(md) {
      padding-top: 140px;
    }
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    z-index: 1;
    color: #fff;
    @include responsive(md) {
      font-size: 50px;
    }
    @include responsive(lg) {
      font-size: 50px;
    }
  }
}

