@import "../../resources/scss/variable";
@import "../../resources/scss/mixin";

.login-form-container {
    width: 100%;
    min-height: 560px;
    max-height: 1920px;
    padding: 3vh 50px;
    height: 75vh;
    top: 5vh;
    flex: 1;
    position: relative;
    background: #141414e6 0% 0% no-repeat padding-box;
    margin: 0 auto;
    box-sizing: border-box;
    display: inline-block;
    pointer-events: all;
    left: 50%;
    transform: translateX(-50%);
    @include responsive(sm) {
        width: 375px;
        height: 75vh;
        top: 10vh;
    }
    @include responsive(xs) {
        width: 375px;
        height: 75vh;
        top: 10vh;
    }
    @include responsive(md) {
        left: 0;
        transform: translateX(0);
        width: 320px;
        margin: unset;
        top: 5vh;
        height: 90vh;
        padding: 3vh 30px;
    }
    @include responsive(lg) {
        left: 0;
        transform: translateX(0);
        width: 340px;
        margin: unset;
        top: 10vh;
        height: 80vh;
    }
    @include responsive(xl) {
        padding: 80px;
        width: 468px;
        // padding: 3vh 30px;
        top: 10vh;
        height: 80vh;
    }
    @include responsive(xxl) {
        padding: 80px;
        width: 468px;
        top: 10vh;
        height: 75vh;
        padding: 5vh 80px;
    }
    .logo {
        width: 100%;
        height: 100px;
        margin-bottom: 1vh;
    }
    .title {
        font-weight: bold;
        color: #fff;
        font-size: 1.6vmin;
        margin-bottom: 0;
        justify-content: center;
        display: flex;
    }
    .description {
        justify-content: center;
        display: flex;
        text-align: center;
        font-size: 14px;
        letter-spacing: 1.4px;
        color: #ffffff;
        opacity: 0.7;
        font-weight: 300;
        margin-bottom: 4vh;

        @include responsive(xxl) {
            margin-bottom: 8vh;
        }

        @include responsive(lg) {
            margin-bottom: 6vh;
        }
        @include responsive(md) {
            margin-bottom: 4vh;
        }
    }
    .links {
        margin-top: 1.2vh;
        color: lightgray;
        font-size: 12px;
        font-style: italic;
        a {
            color: #fff;
            opacity: 0.6;
        }
    }
    .fb-login {
        width: 95%;
        height: 40px;
    }
    .gg-login {
        display: flex;
        flex-direction: row-reverse;
        button {
            width: 95%;
            height: 40px;
        }
    }
    .login-submit {
        margin-top: 2vh;
        width: 100%;
        height: 40px;
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        &:hover {
            background-color: #209005;
            border-color: #209005;
            color: #fff;
        }
    }
    .ant-input-affix-wrapper {
        .ant-input {
            border: none;
            background: transparent;
            border-bottom: 1px solid #ffffff50;
            padding-left: 0 !important;
            border-radius: 0;
            outline: none;
            font-size: 14px;
            font-weight: 300;
            color: #fff;
            &:focus {
                box-shadow: none;
                border-bottom: 1px solid #40a9ff;
            }
        }
        .ant-input-prefix {
            display: none;
        }
    }
    .has-error {
        .ant-input-affix-wrapper {
            .ant-input {
                background-color: transparent !important;
                &:hover,
                &:focus {
                    box-shadow: none;
                    background-color: transparent !important;
                }
            }
        }
    }
}
.input {
    border: none;
    border-bottom: 2px;
    border-bottom-color: white;
}
.support_link {
    color: lightgray;
}
