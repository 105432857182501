@import "variable";

img {
    max-width: 100%
}

.fs-12, .fs-12 * {
    font-size: 12px;
}

.primary-color {
    color: $primary-color;
}