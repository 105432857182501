@import "../../../resources/scss/variable";

.feature-part {
    padding-top: 0.5rem;
    padding-bottom: 88px;
    .img {
        width: 100%;
        margin-bottom: 0.5rem;
        border-radius: 8px;
    }

    .title {
        margin: 0.5rem 0;
        font-size: 18px;
        color: #fff;
    }

    .sku {
        font-style: italic;
        font-size: 14px;
    }
    .price-section {
        border-top: 1px solid #707070;
        padding-top: 5px;
        color: green;
        font-weight: bold;
        margin-bottom: 10px;
        .price {
            font-size: 14px;
            color: $primary-color
        }
    }

    .d-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
