@import "../../resources/scss/mixin";
@import "../../resources/scss/variable";

.page-user-intro {
  margin-bottom: 1rem;
}

.ant-tabs-bar {
  margin-bottom: 5px;
}

.cart .cart-wrapper {
  z-index:1;
  
  .cart-detail {
    border-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 0 16px 0 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $label-color;
    width: 50%;
    border-bottom: 3px solid $border-color;

    &:hover {
      color: #fff;
    }

    &.ant-tabs-tab-active {
      color: #fff;
      border-bottom: 3px solid $primary-color;
    }

    @include responsive(md) {
      font-size: 24px;
    }
  }

  .ant-tabs-ink-bar {
    height: 0;
  }
}

.cart-history {

  .cart-header {
    color: $label-color;
    padding: 5px 0;
    font-size: 14px;
    border-bottom: none;
    margin-left: 0px;
    margin-right: 0px;

    @include responsive(md) {
      margin-left: 14px;
      margin-right: 26px;
    }
  }

  .cart-row {
    color: #fff;
    font-size: 14px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 7px;
    background-color: transparent;

    .ant-col {
      background-color: #262626E6;
      justify-content: center;
      align-items: flex-start;

      &.created-at, &.product-name-col {
        padding: 7px 0;
      }

      > div {
        height: 100% !important;
      }

      .center_col {
        padding: 0;
      }
    }

    .created-time {
      color: $label-color;
      font-size: 12px;
      line-height: 1.3;
    }

    .created-date {
      font-weight: bold;
      line-height: 1.3;
    }

    .product-name {
      line-height: 1.2;
    }

    .product-price {
      font-weight: 700;
      padding: 0 1rem;
      background-color: #000;
      align-items: flex-end;
      justify-content: center;
      display: flex;

      .price {
        font-size: 16px;
      }
    }

    .actions {
      padding-left: 0 !important;
      background-color: transparent;

      .button-cart {
        background-color: $primary-color;
        border-radius: 0 6px 6px 0;
        justify-content: center;
        align-items: center;
        display: flex;

        &:hover {
          background-color: darken($primary-color, 10);
          cursor: pointer;
        }

        i {
          font-size: 18px;
          padding: 0 1rem;
        }
      }
    }
  }
}

.level {
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  border-radius: 4px;
  padding: 1px 10px;

  &.level1 {
    background-color: $level1-bg;
  }

  &.level2 {
    background-color: $level2-bg;
  }

  &.level3 {
    background-color: $level3-bg;
  }
}

.user-level {
  color: #FFFFFF80;
  .ant-row {
    margin-bottom: 0.5rem;
  }

  .title {
    font-weight: bold;
  }

  strong {
    color: #ffffff90;
  }

  .heading {
    border-bottom: 2px solid $border-color;
    padding: 0 16px 0 0;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
  }
}