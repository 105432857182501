@import "../../resources/scss/variable";
@import "../../resources/scss/mixin";

.login-form-container {
    .form-title {
        font-weight: bold;
        color: #fff;
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 7vh;
    }
    .register-form {
        .back-button {
            margin: 1rem 0;
            color: #ffffff90;
            text-align: center;
            display: block;
            font-style: italic;
        }
    }
}