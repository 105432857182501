@import "../../../../resources/scss/variable";
@import "../../../../resources/scss/mixin";


.tab-heading {
  width: 25%;
  text-align: center;
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff40;
  border-left: 1px solid $border-color;
  position: relative;
  padding: 7px;

  .icon {
    font-size: 1.2em;
  }

  @include responsive(md) {
    padding: 0px;
  }

  &:first-child {
    border-left: 0;
  }
  &.active, &:hover {
    cursor: pointer;
    color: #fff;
    border-bottom: 4px solid $primary-color;
  }
  .heading {
    margin-top: 2px;
    height: 0px;
    visibility: hidden;

    @include responsive(md) {
      visibility: visible;
      height: auto;
    }
  }
  .sub-heading {
    font-size: 12px;
    color: #FFCE0060;
    font-style: italic;
    position: absolute;
    bottom: 2px;
  }
  &.coming-soon {
    i {
      color: #FFFFFF20;
    }
    .heading {
      color: #FFFFFF20;
    }
    &:hover {
      cursor: no-drop;
      border-bottom: none;
    }
  }
}