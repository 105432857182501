@import "../../resources/scss/variable";
@import "../../resources/scss/mixin";

.site-collapse-custom-collapse .ant-collapse-header {
  position: relative;
  padding: 0px 0px !important;
  padding-left: 2px !important;
  color: white !important;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}

.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 0px !important;
  color: white !important;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}

.bikes-info {
  display:flex; 
  align-content:center;
  height:100%; 
  flex-direction:column;
  justify-content:space-evenly;
  @include responsive(md) {
    visibility: hidden;
  }
}

.search-header {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  height: 30px;
  font-weight: bold;
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  margin-bottom: 4px;
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  background-color: rgb(44, 44, 44);
  z-index: 11;
  height: 40px;

  @include responsive(md) {
    font-size: 14px;
  }
}

.search-header-column {
  align-items: center;
  display: flex;
  height: 100%;
}

.new_code {
  text-align: left;
  font-size: 9px;
  letter-spacing: 0;
  color: #ffce00c4;
  @include responsive(md) {
    font-size: 11px;
  }
}
.old_code {
  text-align: left;
  font-size: 9px;
  letter-spacing: 0;
  color: #ffffff66;
  text-decoration: line-through;
  @include responsive(md) {
    font-size: 11px;
  }
}
.code {
  text-align: left;
  font-size: 9px;
  letter-spacing: 0;
  color: #ffffff66;

  @include responsive(md) {
    font-size: 11px;
  }
}
.title {
  font-weight: bold;
  color: white;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsive(sm) {
    font-size: 13px;
  }

  @include responsive(md) {
    font-size: 13px;
  }
}
.sub-title {
  font-weight: 400;
  color: lightgray;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include responsive(md) {
    font-size: 13px;
  }
}
.ref-code {
  height: 100%;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 9px;
  padding-left: 5px;

  @include responsive(md) {
    font-size: 11px;
    line-height: 11px;
  }
}
.inventory {
  text-align: center;
  font-size: 19px;
  letter-spacing: 0;
  padding-right: 5px;
  min-width: 50px;
}

.paths {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 28px;

  @include responsive(md) {
    font-size: 15px;
  }
}
