@import "../../resources/scss/mixin";
@import "../../resources/scss/variable";

.home-page {
  position: relative;
  color: #fff;
  background-color: #2C2C2C;

  .title {
    font-weight: 700;
  }

  .cover {
    width: 100%;
    position: absolute;
    top: 0;
    background-size: cover;
    height: 300px;

    @include responsive(sm) {
      height: 350px;
    }

  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;

    @include responsive(sm) {
      padding-top: 90px;
    }

  }

  .page-intro {
    .title {
      z-index: 1;
      height: 80px;
      color: white;
      font-size: 2.1em;
      display: flex;
      justify-content: center;
      font-weight: bolder
    }

    .description {
      z-index: 1;
      word-wrap: wrap;
      width: auto;
      height: 145px;
      max-width: 600px;
      text-align: center;
      margin-top: 10px;
      font-size: 1em
    }
  }
}
