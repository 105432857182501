.ant-table-pagination.ant-pagination {
    float: right;
    margin: 8px 0;
}
.ant-form-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 16px !important;
    vertical-align: top;
}
.ant-layout-header {
    /* height: 64px; */
    padding: 0 0px !important;
    line-height: 64px;
    background: #2c2c2c;
}
.ant-menu-horizontal {
    line-height: 64px;
    height: 100%;
    white-space: nowrap;
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 15px ;
    color: white !important;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #383838;
    border-top: 0;
}
.ant-collapse-content {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    background-color: #383838 ;
    border-top: 1px solid #383838 ;
}
.ant-modal-footer {
    background-color: black;
    padding:0px;
}
.ant-modal-header {
    padding: 16px 24px;
    color: white;
    background: black;
    border-bottom: none !important;
    text-align: center;
}
.ant-modal-title {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    word-wrap: break-word;
}
.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: black;
    border-top: none !important;
    border-radius: 0 0 4px 4px;
}
.ant-form-item-label > label{
    color:white;
}