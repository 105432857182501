@import "../../resources/scss/App.scss";
@import "../../resources/scss/variable";
@import "../../resources/scss/mixin";

.fluid-container {
    max-width: 1920px;
    margin: 0 auto;
}

// ::-webkit-scrollbar {
//     width: 10px;
//     // background-color: transparent;
// }

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes progress-width {
    0% { width: 0; }
    100% { width: 100%; }
}

.scroll-page {
    position: fixed;
    bottom: 0;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    .image-wrap {
        text-align: center;
        height: 35px;
        overflow: hidden;
        img {
            -webkit-animation: spin 8s linear infinite;
            -moz-animation: spin 8s linear infinite;
            animation: spin 8s linear infinite;
        }
    }

    p {
        text-align: center;
        font-size: 12px;
        color: #fff;
        opacity: 0.6;
        margin-bottom: 0;
        text-transform: uppercase;
    }
}
.landing-page {
    background-color: #2c2c2c;
    .page-scroll {
        // pointer-events: none;
        position: relative;
        min-height: 100vh;
        height: 100%;

        &.page-scroll-padding {
            background-color: #2c2c2c;
            &.active {
                display: flex;
            }
        }
    }
    .item-one {
        background: transparent url("/images/landing/page_1.png") center center
            no-repeat padding-box;
    }
    .item-two {
        background: transparent url("/images/landing/bg_banner_gara.png") center center
            no-repeat padding-box;
    }
    .item-three {
        background: transparent url("/images/landing/page_1_3.jpg") center center
            no-repeat padding-box;
    }
    .carousel-item {
        height: 100vh;
        border: 1px solid #707070;
        background-size: cover;
        position: relative;
        z-index: 1;
        &:after {
            background-color: #41414160;
            content: "";
            width: 100%;
            height: 100%;
            z-index: 2;
            position: absolute;
            top: 0;
        }
        .container {
            position: relative;
            .item-detail {
                display: none;
                position: absolute;
                top: 15vh;
                z-index: 9;
                max-width: 100%;
                @include responsive(md) {
                    padding-left: calc(320px + 6vw);
                    display: block;
                }
                @include responsive(xxl) {
                    padding-left: calc(468px + 5vw);
                    display: block;
                }
                @include responsive(xl) {
                    padding-left: calc(468px + 5vw);
                    display: block;
                }
                .number {
                    margin-bottom: 5vh;
                    font-weight: bold;
                    color: #fff;
                    font-size: 24px;
                    opacity: 1;
                    position: relative;
                    @include responsive(lg) {
                        font-size: 26px;
                    }
                    .progress {
                        display: inline-block;
                        width: 120px;
                        height: 3px;
                        top: -7px;
                        left: 12px;
                        position: relative;
                        .carousel-progress {
                            position: absolute;
                            height: 3px!important;
                            background-color: #fff;
                            opacity: 1;
                            animation: progress-width 4.1s ease-in-out;
                            animation-fill-mode:forwards;
                        }
                    }
                }
                .sub-title {
                    color: #fff;
                    font-size: 22px;
                    font-weight: 300;
                    margin-bottom: 1rem;
                    @include responsive(lg) {
                        font-size: 18px;
                    }
                }
                .title {
                    color: #fff;
                    font-size: 36px;
                    font-weight: bold;
                    text-transform: none;
                    line-height: 1.2;
                    margin-left: -3px;
                    margin-bottom: 2rem;
                    padding-right: 2rem;
                    text-shadow: 2px 2px 10px #000000;
                    @include responsive(xl) {
                        font-size: 68px;
                    }
                }
                .description {
                    color: #fff;
                    font-size: 14px;
                    // font-weight: 400;
                    // font: Regular 22px/27px Roboto;
                    text-shadow: 2px 2px 10px #000000;
                    letter-spacing: 0px;
                    padding-right: 40px;
                    @include responsive(xl) {
                        font-size: 18px;
                    }
                    @include responsive(lg) {
                        font-size: 16px;
                    }
                }
                .apps-download {
                    margin-top: 2rem;
                    margin-bottom: 0.5rem;
                    // margin: 2rem;
                    // margin: 2rem;
                    @include responsive(md) {
                        // margin: 2.5rem 0;
                        margin-top: 2.5rem;
                    }
                    a {
                        display: inline-block;
                        width: 140px;
                        margin-right: 1.5rem;
                    }
                }
            }
        }
    }
    .page-scroll-padding {
        justify-content: center;
        flex-direction: column;
        * {
            color: #fff;
        }
        .ant-row {
            display: flex;
        }
        .col-image {
            text-align: center;
            img {
                max-width: 75%;
                margin: 0 auto;
                display: inline-block;
                @include responsive(lg) {
                    max-width: 100%;
                }
            }
        }
        .col-text {
            text-align: center;
            display: flex;
            align-items: center;
            @include responsive(lg) {
                text-align: right;
            }
            .title {
                flex-flow: wrap;
                white-space: normal;
                font-size: 40px;
                text-transform: none;
            }
            .description {
                font-size: 14px;
                margin: 2rem 0;
            }
            .btn {
                margin-top: 2rem;
                font-size: 24px;
                min-width: 245px;
                padding: 7px 0;
                border: 1px solid #fff;
                font-weight: bold;
                background-color: transparent;
                border-radius: 4px;
                &:hover {
                    border-color: green;
                    cursor: pointer;
                }
            }
        }
        &:nth-child(2n + 1) {
            .col-text {
                @include responsive(lg) {
                    text-align: left;
                }
            }
            .ant-col {
                &:first-child {
                    order: 1;
                }
                &:last-child {
                    order: 0;
                }
                @include responsive(lg) {
                    &:first-child {
                        order: 0;
                    }
                    &:last-child {
                        order: 1;
                    }
                }
            }
        }
        &.our-commit {
            background-image: url("/images/landing/page_5.png");
            background-size: contain;
            padding: -3rem 0;
            position: relative;
            background-position-y: bottom;
            background-repeat: no-repeat;
            .container {
                position: relative;
                z-index: 2;
                height: 100%;
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: space-around;
            }
            &:after {
                background: linear-gradient(rgba(44,44,44,1),rgba(44,44,44,0.8),rgba(44,44,44,0.1));

                background: -moz-linear-gradient(rgba(44,44,44,1),rgba(44,44,44,0.8),rgba(44,44,44,0.1));
                background: -webkit-linear-gradient(rgba(44,44,44,1),rgba(44,44,44,0.8),rgba(44,44,44,0.1));
                background: linear-gradient(rgba(44,44,44,1),rgba(44,44,44,0.8),rgba(44,44,44,0.1));
                // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2c2cd1', endColorstr='#999999',GradientType=0 );

                position: absolute;
                content: "";
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .title {
                font-size: 32px;
                margin-bottom: 0.5rem;
                
                @include responsive(lg) {
                    font-size: 40px;
                    margin-bottom: 1rem;
                }
            }
            .commit {
                .commit-title {
                    font-size: 18px;
                    margin: 0.6rem 0 0.6rem 0;
                    @include responsive(lg) {
                        font-size: 24px;
                    }
                }
                .description {
                    font-size: 14px;
                }
            }
            .footer {
                h2 {
                    font-size: 35px;
                    margin-bottom: 0.2rem;
                    line-height: 1.3;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
        }
    }
    .login-ui {
        z-index: 10;
        position: absolute;

        // max-width: 1380px;
        // margin-right: auto;
        // margin-left: auto;
        // padding-left: 5px;
        // padding-right: 5px;
        //   padding-left: 1rem;
        //   padding-right: 1rem;
            width: 468px;
            margin-left: 8vw;
      
        @include responsive(xxs) {
            width: 90%;
            margin-left:auto;
            margin-right: auto;
        }
        @include responsive(xs) {
            width: 90%;
            margin-left:auto;
            margin-right: auto;
        }
        @include responsive(sm) {
            width: 90%;
            margin-left:auto;
            margin-right: auto;
        }
        @include responsive(md) {
            width: 468px;
            margin-left: 5vw;
        }
        @include responsive(lg) {
            width: 468px;
            margin-left: 5vw;
        }
        @include responsive(hd) {
            width: 468px;
            margin-left: 8vw;
        }
        // @include responsive(lg) {
        //     left: 0;
        //     transform: translateX(0);
        //     width: 340px;
        //     margin: unset;
        //     top: 10vh;
        //     height: 80vh;
        // }
        // @include responsive(xl) {
        //     padding: 80px;
        //     width: 468px;
        //     padding: 3vh 30px;
        //     top: 10vh;
        //     height: 80vh;
        // }
        // @include responsive(xxl) {
        //     padding: 80px;
        //     width: 468px;
        //     top: 10vh;
        //     height: 75vh;
        //     padding: 5vh 80px;
        // }
    }
    .commit-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0px !important;

        .commit-box {
            width: 100%;
            padding:20px;
            margin-bottom: 0px !important;
            
            @include responsive(sm) {
                width: auto;
                flex: 1;
                margin-bottom: 0px !important;
            }
        }
    }
}
