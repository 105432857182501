@import "../../resources/scss/mixin";
@import "../../resources/scss/variable";

.body {
  background-color: rgb(17, 31, 16);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif;
}

#expanded_search {
  align-items: center;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #1c1c1c;
  justify-content: center;
  display: flex;
  padding: 15px;

  .search-box {
    width: 100%;
    max-height: 50px;
    height: 5vh;
    background: #383838 0 0 no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    position: relative;
    display: flex;

    .search-input {
      width: 90%;
      height: 100%;
      background: transparent;
      border: none;
      color: white;
    }

    .search-icon {
      color: rgba(255, 255, 255, 1);
      position: absolute;
      right: 0;
      height: 100%;
      width: auto;
      font-size: 24px;
      padding: 8px;
    }
  }
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 100%;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;

  .header-wrapper {
    display: flex;
    height: 100%;
    color: white;
    align-items: center;
    max-width: 100%;
    @include responsive(md) {
      max-width: 60%;
    }
  }

  .logo {
    fill: white;
    width: 100%;
    height: 100px;
  }

  .headerLogo {
    fill: white;
    width: 56px;
    height: 100%;
    background-color: #1c1c1c;
    padding: 10px;
  }

  .search-wrapper {
    width: 30%;
    min-width: 200px;
    justify-content: center;
    display: flex;

    .search-box {
      max-width: 300px;
      width: 100%;
      max-height: 50px;
      height: 5vh;
      background: #383838 0 0 no-repeat padding-box;
      border-radius: 12px;
      opacity: 1;
      position: relative;
      display: flex;
      margin-right: 5px;

      .search-input {
        width: 90%;
        height: 100%;
        background: transparent;
        border: none;
        color: white;
      }

      .search-icon {
        color: rgba(255, 255, 255, 1);
        position: absolute;
        right: 0;
        height: 100%;
        width: auto;
        font-size: 24px;
        padding: 8px;
      }
    }
  }

  .profile-box {
    display: flex;
    min-width: 300px;
    height: 100%;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: right;
    background-color: #1c1c1c;

    // 576px window width and more
    @include responsive(sm) {
      width: 50%;
      justify-content: right;
      background-color: transparent;
    }
    // 992px window width and more
    @include responsive(lg) {
      width: 30%;
      justify-content: right;
    }
    // 1400px window width and more
    @include responsive(rwd) {
      width: 30%;
      justify-content: right;
    }

    .badge-wrapper {
      .ant-badge {
        // 576px window width and more
        @include responsive(sm) {
          margin: 0 1rem;
        }

        @include responsive(xxl) {
          margin: 0 2rem;
        }
        vertical-align: middle;

        &:first-child {
          margin-right: 0.68rem;
          @include responsive(sm) {
            margin-right: 1rem;
          }
        }

        &:last-child {
          margin-left: 0.68rem;
          margin-right: 1rem;
          @include responsive(sm) {
            margin-left: 1rem;
          }
        }

        i {
          font-size: 20px;
          margin-top: 5px;
          color: #fff;
        }

        .ant-badge-count {
          top: 6px;
          min-width: 18px;
          height: 18px;
          line-height: 18px;
          background: $primary-color;
          border-radius: 9px;
          box-shadow: none;

          .ant-scroll-number-only {
            height: 18px;

            > p {
              height: 18px;
              margin: 0;
              font-weight: bold;
              font-size: 10px;
            }
          }
        }
      }
    }

    .mobile-header-extra {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 4px;

      @include responsive(sm) {
        display: none;
      }
    }

    .profile-dropdown {
      height: 100%;
      margin-right: 15px;
      @include responsive(sm) {
        margin-right: 0px;
      }

      .profile-dropdown-list {
        background-color: #1c1c1c;
        display: flex;
        align-items: center;

        .profile-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 10px;

          @include responsive(sm) {
            margin-left: 30px;
          }

          .profile-name {
            color: white;
            font-weight: bold;
            font-size: 1em;
            line-height: 16px;
          }

          .profile-coin {
            font-weight: bolder;
            font-size: 1em;
            line-height: 16px;
            text-align: right;
          }
        }

        .setting {
          display: none;
          margin-left: 20px;
          margin-right: 20px;
          font-size: 2.5vmin;
          // 576px window width and more
          @include responsive(sm) {
            display: block;
          }
        }
      }
    }
  }

  .sub_header_menu_box {
    background: transparent;
  }

  .sub_header_menu_item {
    background: transparent;
  }
}

.nav {
  display: flex;
  height: 30px;
  align-items: center;
  color: lightgray;
  font-size: 12px;
  flex-wrap: wrap;

  .home-link {
    padding-left: 8px;
    color: white;
    line-height: 18px;
  }

  .menu-link {
    padding-left: 15px;
    line-height: 18px;
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .menu-border {
    height: 2px;
    width: 30px;
    background-color: green;
    margin-bottom: 8px;
    margin-left: 0;
  }
}

#footer {
  text-align: center;
  background-color: #000;
  color: #fff;
}

.avatar_small {
  width: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  border: 0.7vmin solid rgba(255, 255, 255, 0.3);
}

.center_col {
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  padding: 5px;
}

.zoom_image {
  z-index: 0;
  flex: 1;
  margin: 10px;
  max-width: 512px;
  border: solid white 1px;
}

.zoom_image div > div > img {
  z-index: 1 !important;
  position: relative;
}

.zoom_image div > div > div > img {
  z-index: 0 !important;
  max-width: none !important;
}
